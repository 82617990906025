import './App.scss'
import player from './img/player.png'
import jordan from './img/jordan.jpg'
import pete from './img/pete.jpg'

function App() {
  return (
    <div className="App">

      <header className="Header">
        <div className="logo">
          {"Rubicon Metals & Rarities"}
        </div>
      </header>

      <div className="Hero">
        <div className="container">
          <div className="primary">
            <div className="bg-img" />
            <img className="bg-player" src={player} alt="Mickey Mantle card"/>
            <h2>Let us buy your old baseball cards, coins, and collectables</h2>
            <ul>
              <li>We come to you</li>
              <li>We give you an offer for you</li>
              <li>You turn your old clutter into money</li>
            </ul>

            <a href="#contact" className="button">
              <span>Get in touch now</span>
            </a>
          </div>

          <div className="secondary-container">
            <div className="secondary secondary--a">
              <div className="bg-img" />
              <h3>Our process is simple</h3>
            </div>

            <div className="secondary secondary--b">
              <div className="bg-img" />
              <h3>Sell us your bulk clutter</h3>
            </div>

          </div>

        </div>
      </div>

      <div className="About">
        <img src={jordan} alt="jordan card" className="card card--a" />
        <img src={pete} alt="pete card" className="card card--b" />
        <div className="container">
          <div className="content">
            <h2>Who We Are</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ante ac libero sodales vulputate ac id enim. Etiam interdum eros ut mi posuere, non ultricies elit gravida. Integer blandit ligula vel massa convallis pulvinar quis vel libero. Aliquam id semper magna. Sed venenatis lectus tortor.</p>
          </div>
          <div className="content">
            <h2>How it Works</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ante ac libero sodales vulputate ac id enim. Etiam interdum eros ut mi posuere, non ultricies elit gravida. Integer blandit ligula vel massa convallis pulvinar quis vel libero. Aliquam id semper magna. Sed venenatis lectus tortor.</p>
          </div>
          <div className="content">
            <h2>We Come to You</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ante ac libero sodales vulputate ac id enim. Etiam interdum eros ut mi posuere, non ultricies elit gravida. Integer blandit ligula vel massa convallis pulvinar quis vel libero. Aliquam id semper magna. Sed venenatis lectus tortor.</p>
          </div>
          <div className="content">
            <h2>(Something about why)</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ante ac libero sodales vulputate ac id enim. Etiam interdum eros ut mi posuere, non ultricies elit gravida. Integer blandit ligula vel massa convallis pulvinar quis vel libero. Aliquam id semper magna. Sed venenatis lectus tortor.</p>
          </div>
        </div>
      </div>

      <div className="Contact" id="contact">

        <h1 className="title">Get In Contact Today!</h1>

        <a href="mailto:something@something.com?subject=Question" className="link-button">
          I have a question
        </a>

        <a href="mailto:something@something.comsubject=Meeting" className="link-button">
          Schedule a meeting
        </a>

        <a href="mailto:something@something.comsubject=Other" className="link-button">
          Other
        </a>
      </div>

      <div className="Footer">

      </div>

    </div>
  );
}

export default App;
